html{
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #484242;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  text-decoration: none;
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateX(0px);
  }
  to {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes bounce {
  from {
    -moz-transform: translateX(0px);
  }
  to {
    -moz-transform: translateX(10px);
  }
}

@keyframes bounce {
  from {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  to {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
  }
}


@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);

  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);

  }
}

@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);

  }
  100% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
  }
}

@keyframes slide-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes hide-slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}